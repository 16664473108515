const LibValidacion = {
  getIsNotEmpty: (valor, nombre = 'valor') => {
    if (valor === null || valor === undefined) {
      return false;
    }

    if (typeof valor === 'string') {
      if (valor.toString().trim() === '') {
        return false;
      }
    }

    return true;
  },
  isEmailValid: email => {
    /*https://tylermcginnis.com/validate-email-address-javascript/*/

    if (email === null || email === undefined) {
      return false;
    }

    if (typeof email !== 'string') {
      return false;
    }

    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  }
};

export default LibValidacion;
