import React, {Component} from 'react';

import './NombreCliente.css';
import MsgEnProceso from "../../../components/MsgEnProceso/MsgEnProceso";

class NombreCliente extends Component {

  constructor(props, context) {
    super(props, context);
    this.renderSol.bind(this);
    this.renderNewSol.bind(this);
    this.onChangeTexto.bind(this);
  }

  state = {
    nombreCliente: "",
    isEnProceso: false
  };

  onChangeTexto = (event) => {
    const texto = event.target.value;
    this.setState({nombreCliente: texto});

  };

  onClickComenzar = async () => {
    const texto = this.state.nombreCliente;

    this.setState({
      isEnProceso: true
    });

    await this.props.onComenzarSolicitud(texto);
    //reiniciar el nombre para el siguiente registro
    this.setState({
      nombreCliente: '',
      isEnProceso: false
    });
  };

  onSubmit = async(event)=>{
    event.preventDefault();
    this.onClickComenzar();
  } ;

  renderNewSol() {


    const btn = this.state.nombreCliente === "" ? null : (
        <button
            className="btn btn-primary  btn-sm mt-2"
            onClick={event => this.onClickComenzar()}

        >
          <i className="fas fa-play"/>
          Comenzar Solicitud
        </button>
    );

    return (
        <>
          <h4 className="tituloNombre">
            Nueva solicitud del cliente
          </h4>
          <form onSubmit={this.onSubmit} >
            <input type="text"

                   value={this.state.nombreCliente}
                   onChange={event => this.onChangeTexto(event)}
                   className="form-control form-control-sm"/>
          </form>


          <MsgEnProceso isEnProceso={this.state.isEnProceso}/>

          <div className="text-center">
            {btn}
          </div>

        </>
    );
  }

  renderSol(sol) {
    return (
        <>
          <h2 className="tituloNombre">
            {sol.nombreCliente}
          </h2>
          <h4 className="tituloid" title="ID">{sol._id}</h4>
        </>
    );
  }

  render() {

    const sol = this.props.sol;


    if (sol === null) {
      return this.renderNewSol();
    } else {
      return this.renderSol(sol)
    }

  }
}

export default NombreCliente;
