import React, {Component} from 'react';
import ItemDocSolicitudTipo from "./ItemDocSolicitudTipo";
import ObserverWindowH from "../../../lib/ObserverWindowH";
import NombreCliente from "./NombreCliente";


class BuilderSolicitud extends Component {




  componentDidMount() {

    let fn = h => {
      const wrapper = document.getElementById('wrapperListaDoc');
      if (wrapper === null) {
        return;
      }
      wrapper.style.height = `${h - 212}px`;
    };

    ObserverWindowH.onWindowsRezise.subscribir('wrapperListaCliente', fn);

    fn(window.innerHeight);
  }


  render() {


    const solTipo = this.props.solTipo;
    const sol = this.props.sol;

    const onImageAdd = this.props.onImageAdd;


    const listaDocTipo = solTipo.listaDocTipo;


    const listaItemDoc = sol === null ? null : listaDocTipo
        .map((item, index) => {

          return <ItemDocSolicitudTipo
              key={index + 1}
              index={index + 1}
              sol={sol}
              documento={item}
              onImageAdd={onImageAdd}
          />
              ;

        })
    ;


    return (
        <div className="pageInner fillFlex" id="wrapperListaDoc">
          <NombreCliente
              onComenzarSolicitud={this.props.onComenzarSolicitud}
              sol={sol}/>
          {listaItemDoc}
        </div>
    );
  }
}

export default BuilderSolicitud;
