import React, {Component} from 'react';
import TopBar from '../../components/topbar/TopBar';
import UrlPages from '../UrlPages';
import BtnBar from "./components/BtnBar";

import './solicitudCrear.css';
import BuilderSolicitud from "./components/BuilderSolicitud";

import AppContext from '../../app/AppContext';
import ProCalculoAvance from "./proceso/ProCalculoAvance";
import AuthService from "../../services/authService/AuthService";
import {Button, Modal} from "react-bootstrap";


class SolicitudCrearPage extends Component {


  constructor(props, context) {
    super(props, context);
    this.state = {
      avance: 0,
      isShowModal: false
    };
    this.onShowModalSelectArea.bind(this);
  }


  static contextType = AppContext;

  async onChangeTipoSelected(event) {
    const idSelected = event.target.value;
    const solTipoSelected = this.context.listaSolTipo.find(item => {
      return item._id === idSelected;
    });

    this.context.onSetSolTipo(solTipoSelected);
  }

  onImageAdd() {
    const avance = ProCalculoAvance(this.context.sol.listaImg, this.context.sol.listaDocTipo);
    this.setState({avance});

  }

  getSelecTipo(listaSolTipo, solTipo) {

    if (listaSolTipo.length === 0) {
      return null;
    }

    let listaOptions = listaSolTipo
        .map(item => {

          return (
              <option
                  value={item._id}
                  key={item._id}
              >{item.tipo}</option>
          );

        })
    ;

    return (<select className="form-control cbxTipoSolicitud mt-2 mb-2"
                    onChange={event => this.onChangeTipoSelected(event)}
                    value={solTipo._id}
    >
      {listaOptions}
    </select>);

  }

  onShowModalSelectArea = (b) => {
    if (AuthService.getListaAreasVendedor().length < 2) {
      return;
    }
    this.setState({isShowModal: b});
  };


  setAreaSelected = (area) => {
    AuthService.setAreaSelected(area);
    this.setState({isShowModal: false});
  };

  render() {

    if (!this.context.isLoadedDataAux) {
      return null;
    }

    const area = AuthService.getArea();

    const selectTipo = this.getSelecTipo(this.context.listaSolTipo, this.context.solTipo);

    const avance = this.state.avance;

    const listaAreas = AuthService.getListaAreasVendedor();

    let cmdChangeArea = null;

    if (listaAreas.length > 0) {
      cmdChangeArea = (<i className="fas fa-exchange-alt cursor-pointer" title="Cambiar de área"/>);
    }

    const onShowModalSelectArea = this.onShowModalSelectArea;

    const listaOpcionArea = listaAreas
        .map(area => {
          return <p className="opcionAreaSel"
                    key={area.idArea}
                    onClick={ event=>this.setAreaSelected(area)}>{area.area}</p>
        })
    ;


    return (

        <AppContext.Consumer>
          {context => (
              <div className="pageCrear">

                <TopBar
                    titulo="Crear Solicitud"
                    usuario={context.usuario}
                    currentUrlRel={UrlPages.SOLICITUD_CREAR}
                />

                <p className="nombreAreaSel"
                   onClick={event => onShowModalSelectArea(true)}
                   title="Are de Ventas">{cmdChangeArea}
                  {area}
                </p>

                {selectTipo}

                <BuilderSolicitud
                    solTipo={context.solTipo}
                    sol={context.sol}
                    onComenzarSolicitud={context.onComenzarSolicitud}
                    onImageAdd={() => this.onImageAdd()}
                />
                <BtnBar
                    sol={context.sol}
                    avance={avance}
                    onAddSolFinalizada={context.onAddSolFinalizada}
                    onCancelarSolicitud={context.onCancelarSolicitud}
                />
                <Modal
                    show={this.state.isShowModal}
                    onHide={event => onShowModalSelectArea(false)}
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Elegir Área</Modal.Title>
                  </Modal.Header>

                  <Modal.Body>
                    {listaOpcionArea}
                  </Modal.Body>

                  <Modal.Footer>
                    <Button variant="secondary"
                            onClick={event => this.onShowModalSelectArea(false)}
                    >Cancelar</Button>
                  </Modal.Footer>
                </Modal>
              </div>

          )
          }
        </AppContext.Consumer>


    );
  }
}

export default SolicitudCrearPage;
