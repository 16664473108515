import React, {Component, Fragment} from 'react';

import './MsgItem.css';

function MsgItem ({msg}){

  const json=JSON.stringify(msg);
  return (<div className="itemMsg">
    <h5 className="tituloEstatus">{msg.nombreCliente} </h5>
    <h5>{msg.estatus}</h5>
    <p><i className="fas fa-com"/>Comentario: {msg.com}</p>
  </div>);

}

export default MsgItem;
