import React, { Component } from 'react';
import LibToast from '../../lib/LibToast';
import LibValidacion from '../../lib/LibValidacion';

class FormLogin extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      email: props.email,
      password: '',
      isEnProceso: props.isEnProceso
    };
  }

  onTxtChange = (campo, valor) => {
    let dic = {};
    dic[campo] = valor;
    this.setState(dic);

    if (campo === 'email') {
      this.props.onUpdateEmail(valor);
    }
  };

  onSubmit = async event => {
    event.preventDefault();

    const email = this.state.email.trim();
    const pass = this.state.password.trim();

    let isValid = true;

    if (email === '') {
      LibToast.alert('Falta el Email');
      isValid = false;
    } else {
      //validar email
      isValid = LibValidacion.isEmailValid(email);
    }

    if (pass === '') {
      LibToast.alert('Falta el Password');
      isValid = false;
    }

    if (!isValid) {
      return;
    }

    await this.props.onSubmitForm(email, pass);
  };

  render() {
    const iconLoading = this.props.isEnProceso && (
      <div className="center-align">
        <i className="fa fa-cog fa-spin " />
        <span className="pl-1"> En Proceso</span>
      </div>
    );

    return (
        <form onSubmit={this.onSubmit} className="login-form">

        <div className="form-group">
          <label htmlFor="email">
            <i className="fas fa-envelope mr-1" />
             Email address
          </label>
          <input
              id="email"
              value={this.state.email}
              className="form-control"
              placeholder="Email"
              onChange={event => this.onTxtChange('email', event.target.value)}
              type="email"
          />
        </div>

        <div className="form-group">
          <label htmlFor="password">
            <i className="fas fa-key mr-1" />
            Password
          </label>
          <input
              id="password"
              value={this.state.password}
              className="form-control"
              onChange={event =>
                  this.onTxtChange('password', event.target.value)
              }
              type="password"
          />
        </div>

        <div className="form-group text-center">
          <button className="btn btn-primary w-50">
            Login
          </button>
        </div>

        {iconLoading}

        <div className="form-group mt-5">
          <p className="text-right">
              <span
                  className="linkRecordar"
                  onClick={event => this.props.onToogleForm()}
              >
                Olvide mi password
                <i className="fas fa-arrow-circle-right ml-1" />
              </span>
          </p>
        </div>

      </form>
    );
  }
}

export default FormLogin;
