import React, {Component} from 'react';


import HOCProtectedPage from "../HOCProtectedPage";

import SolicitudCrearPage from "./SolicitudCrearPage";


function SolicitudCrearPageHOC( {listaTipoSolicitud}) {


  return (

      <HOCProtectedPage>
        <SolicitudCrearPage
            listaTipoSolicitud={listaTipoSolicitud} />
      </HOCProtectedPage>

  );

}

export default SolicitudCrearPageHOC;
