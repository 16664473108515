import React, {Component} from 'react';
import DataService from "../../../services/dataService/DataService";
import LibToast from "../../../lib/LibToast";
import DataReqJson from "../../../lib/DataReqJson";
import './ItemDocSolicitudTipo.css';
import MsgEnProceso from "../../../components/MsgEnProceso/MsgEnProceso";


class ItemDocSolicitudTipo extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      isEnProceso: false,
      listaImg: []
    }
  }


  async onChangeFile(event) {

    if(event.target.files.length===0){
      this.setState({
        isEnProceso: false
      });
      return ;
    }

    let file = event.target.files[0];

    const {sol, documento} = this.props;

    const idDocTipo = documento._id;

    const fileName = file.name;
    const indexPunto = fileName.indexOf('.');
    if (indexPunto < 0) {
      return;
    }


    this.setState({
      isEnProceso: true
    });

    //1.-solicitar el link para subir la imagen ********* */
    const ext = fileName.substring(indexPunto + 1).toLowerCase();
    let respuesta = await DataService.getUploadS3Url(sol._id, idDocTipo, ext);

    if (!respuesta.success) {
      LibToast.alert(respuesta.msg);
      return;
    }


    const {urlS3, key} = respuesta.data;


    //2.-subir directamente a S3 la iamgen ************** */
    const respuestaS3 = await DataReqJson.requestPUTFile(urlS3, file, fileName);


    if (respuestaS3.toString() !== "") {
      LibToast.alert('Error de  S3 al subir imagen :' + respuestaS3);
      return;
    }

    /*3.- solicitar el url signed de la imagen ********* */

    const respuestaUrl = await DataService.getS3Url(key);

    if (!respuestaUrl.success) {
      LibToast.alert('Error de S3 al solicitar :' + respuestaUrl);
      return;
    }

    const imgNew = {
      ext,
      idDocTipo: idDocTipo,
      url: respuestaUrl.data.url
    };

    sol.listaImg.push(imgNew);


    const listaImg = sol.listaImg.filter(item => {
      return item.idDocTipo === idDocTipo
    });

    this.setState({
      listaImg,
      isEnProceso: false
    });

    LibToast.success("Nueva Imagen Agregada");

    this.props.onImageAdd()
  }


  render() {

    const {documento, sol, index} = {...this.props};

    const listaImg = sol.listaImg
        .filter(item => {
          return item.idDocTipo === documento._id
        })
        .map((item, index) => {

          if(item.ext ==='pdf'){

            return <div className="text-center">
              <i className="far fa-file-pdf" /><br/>
              <a target="_blank" href={item.url} >Ver PDF</a>
            </div>
          }

          return <img src={item.url}
                      className="imgDocCaptured"
                      alt={item.url} key={index}/>
        });


    const labelOpcional = documento.isNoRequerido ? <span className="labelOpcional">Opcional</span> : null;

    const idDocTipo = documento._id;
    const controllId = `inputdoc-${idDocTipo}`;


    return (
        <div className="tipoDocSolicitud">
          <h4>
            <span className="badge badge-secondary mr-1">
              {index}
            </span>
            {documento.docTipo}
            {labelOpcional}
          </h4>
          <div className="wrapperCmdFoto ">
            <input type="file"
                   id={controllId}
                   accept="image/*,application/pdf"
                   capture="camera"
                   onChange={event => this.onChangeFile(event)}
            />
            <label className="btn"
                   htmlFor={controllId}
                   title="Capturar Documento">
              <i className="fa fa-camera mr-0"/>
            </label>

            <MsgEnProceso isEnProceso={this.state.isEnProceso}/>
            <div className="imgFiles">
              {listaImg}
            </div>
          </div>


        </div>
    );
  }
}

export default ItemDocSolicitudTipo;
