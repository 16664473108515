const UrlPages = {
  LOGIN: '/',
  SOLICITUD_CREAR: '/solicitud_crear',
  SOLICITUD_VER: '/solicitud_ver',
  SOLICITUD_INDEX: '/solicitud_index',
  VER_PERFIL: '/perfil',
  LOGOUT: '/logout',
  MSG_INDEX: '/msg_index'
};

export default UrlPages;
