import React from 'react';

import AuthService from '../../services/authService/AuthService';

import UrlPages from '../UrlPages';

function LogoutPage() {
  AuthService.setLogOut();

  window.location = UrlPages.LOGIN;

  return null;
}

export default LogoutPage;
