import React, { Component } from 'react';
import LibToast from '../../lib/LibToast';

class FormRecuperacionExe extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      email: props.email,
      password: '',
      passwordConfirmacion: '',
      isEnProceso: props.isEnProceso
    };
  }

  onTxtChange = (campo, valor) => {
    let dic = {};
    dic[campo] = valor;
    this.setState(dic);

    if (campo === 'email') {
      this.props.onUpdateEmail(valor);
    }
  };

  onSubmit = async event => {
    event.preventDefault();

    const token = this.state.token.trim();
    const pass = this.state.password.trim();
    const passConfirm = this.state.passwordConfirmacion.trim();

    let isValid = true;

    if (token === '') {
      LibToast.alert('Falta el Token');
      isValid = false;
    }

    if (pass === '') {
      LibToast.alert('Falta el Password');
      isValid = false;
    }

    if (passConfirm === '') {
      LibToast.alert('Falta la confirmación del Password');
      isValid = false;
    }

    if (passConfirm !== pass) {
      LibToast.alert('La confirmación del password es incorrecta');
      isValid = false;
    }

    if (!isValid) {
      return;
    }

    await this.props.onSubmitForm(token, pass);
  };

  render() {
    const iconLoading = this.props.isEnProceso && (
      <div className="center-align">
        <i className="fa fa-cog fa-spin " />
        <span className="pl-1"> En Proceso</span>
      </div>
    );

    return (
      <form onSubmit={this.onSubmit} className="login-form">
        <div className="">
          <p>Hola</p>
          <h4 className="text-center">{this.props.email}</h4>
          <p className="mt-4">
            Se ha enviado <strong>a tu correo</strong> un código de autorización
            para indicar tu password.
          </p>
          <p>Este código tiene una validez de 15 minutos</p>
          <p>
            Consulta tu correo para obtener el código e indicar el nuevo
            password
          </p>
          <p>Tienes 3 intentos para poner correctamente el token.</p>
        </div>


        <div className="form-group">
          <label htmlFor="email">
            <i className="fas fa-code mr-1" />
            TOKEN de Autorización
          </label>
          <input
              id="email"
              value={this.state.token}
              className="form-control"
              placeholder="Token"
              onChange={event => this.onTxtChange('token', event.target.value)}
              type="text"
          />
        </div>


        <div className="form-group">
          <label htmlFor="password1">
            <i className="fas fa-key mr-1" />
            Nuevo Password
          </label>
          <input
              id="password1"
              value={this.state.password}
              className="form-control"
              onChange={event =>
                  this.onTxtChange('password', event.target.value)
              }
              type="password"
          />
        </div>


        <div className="form-group">
          <label htmlFor="passwordConfirm">
            <i className="fas fa-key mr-1" />
            Confirmación del Nuevo Password
          </label>
          <input
              id="passwordConfirm"
              value={this.state.passwordConfirmacion}
              className="form-control"
              onChange={event =>
                  this.onTxtChange('passwordConfirmacion', event.target.value)
              }
              type="password"
          />
        </div>


        <div className="form-group text-center">
          <button className="btn btn-primary w-50">
            Actualizar Password
          </button>
        </div>

        {iconLoading}



        <div className="form-group mt-5">
          <p className="text-right">
              <span
                  className="linkRecordar"
                  onClick={event => this.props.onToogleForm()}
              >
                Olvide mi password
                <i className="fas fa-arrow-circle-right ml-1" />
              </span>
          </p>
        </div>

      </form>
    );
  }
}

export default FormRecuperacionExe;
