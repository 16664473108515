import React, {Component} from 'react';
import LibToast from "../../../lib/LibToast";
import ProgressBar from "react-bootstrap/ProgressBar";
import DataService from "../../../services/dataService/dataApi/DataApi";
import AuthService from "../../../services/authService/AuthService";


class BtnBar extends Component {


  async onClickEnviar() {


    if (this.props.avance < 100) {
      LibToast.alert(`Faltan documentos en la solicitud`);
      return;
    }
    const sol = this.props.sol;

    const idSolicitud = sol._id;
    const nombreCliente = sol.nombreCliente;



    let respuesta = await DataService.finalizarSolicitud(sol);

    if (!respuesta.success) {
      LibToast.alert(respuesta.msg);
      return;
    }



    LibToast.success(`Solicitud enviada ${nombreCliente}`);

    const fecha = respuesta.data.fecha;


    const isCambioE = true;
    const idUsuario = AuthService.getUser().idUsuario;
    const tagEstatus='Esperando Aprobación';
    console.log(AuthService.getUser());
    const nick = AuthService.getUser().nick;

    /* Agregar lka solicitud al listado */
    sol.idEstatus = 'aprob';
    const com = {
      comentario:"Enviado a Mesa de Control",
      fecha,  nick, idUsuario, isCambioE, tagEstatus, ts: Date.now()};

    sol.listaCom = [com];


    this.props.onAddSolFinalizada(sol);
    this.props.onCancelarSolicitud();

  }

  render() {

    if (this.props.sol === null) {
      return null;
    }

    const avance = this.props.avance;

    const label = avance === 0 ? 'Iniciando Solicitud' : (avance === 100 ? "Lista para enviar" : `${avance}% Completada`);

    const progressBar = avance === 0 ? <p className="text-center m-0">{label}</p> :
        <ProgressBar variant="success" now={avance} label={label}/>;

    const cssBtnEnviar = avance === 100 ? "btn btn-primary divFG50pc" : "btn btn-secondary  divFG50pc";


    return (
        <>

          <div className="pb-1 pt-1">
            {progressBar}
          </div>

          <div className="divFex">
            <button
                className="btn btn-danger divFG50pc"
                onClick={event => this.props.onCancelarSolicitud()}
            >
              <i className="fa fa-times"/>
              Cancelar
            </button>

            <button className={cssBtnEnviar}
                    onClick={event => this.onClickEnviar()}
            >
              <i className="fa fa-paper-plane"/>
              Enviar
            </button>
          </div>
        </>
    );
  }
}

export default BtnBar;
