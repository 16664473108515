import React, {Component, Fragment} from 'react';
import './SolItem.css';
import DataService from "../../services/dataService/DataService";
import LibToast from "../../lib/LibToast";
import MsgEnProceso from "../../components/MsgEnProceso/MsgEnProceso";
import DataReqJson from "../../lib/DataReqJson";
import LibMsgWSService from "../../services/msgWSService/LibMsgWSService";

class SolItem extends Component {


  constructor(props, context) {
    super(props, context);
    this.state = {
      idDocTipoSelected: "",
      isEnProceso: false
    }
  }

  onClicVerSol = async () => {
    const sol = this.props.sol;

    const respuesta = await DataService.getSol(sol._id);

    if (!respuesta.success) {
      LibToast.alert(respuesta.msg);
      return;
    }

    const solNew = respuesta.data;

    solNew.isVerSol = true;

    this.props.onUpdateSol(solNew);


  };

  onChangeEvent = (idDocTipoSelected) => {
    this.setState({idDocTipoSelected})
  };

  render() {

    const sol = this.props.sol;

    const index = this.props.index + 1;
    const {listaEstatusUpdate, nombreCliente, tipo, estatus, fregDMY, fregHora, idEstatus, isVerSol} = this.props.sol;

    const classEstatus = 'tituloEstatus ' + idEstatus;
    const cssSol = 'itemSol ' + idEstatus + (sol.isUpdated ? 'solUpdated' : '');

    const tagUpdated = sol.isUpdated ? <h4 className="tituloActualizado">Actualizado por MC</h4> : null;

    let listaCom = this.props.sol.listaCom;

    if (!listaCom) {
      listaCom = [];
    }

    const listaItemCom = listaCom
        .map((item, index) => {
          return (<li key={index}>
            {item.fecha} <br/>
            {item.comentario} - {item.nick}
          </li>);
        });


    let elemVerSol = null;

    let listaImg = [];


    const numFotos = sol.listaImg.length;
    let elemUpLoad = null;

    if (isVerSol) {

      let listaOption = sol.listaDocTipo
          .map((item) => {
            return <option key={item._id} value={item._id}>{item.docTipo}</option>;
          })
      ;

      const controllId = 'doc_' + sol._id;

      elemUpLoad = (
          <Fragment>
            <div className="mb-4">
              <label>Agregar Documento</label>
              <select
                  value={this.state.idDocTipoSelected}
                  onChange={event => this.onChangeEvent(event.target.value)}
                  className="form-control-sm"
                  style={{width: '100%'}}>
                {listaOption}
              </select>

              <div className="wrapperCmdFoto mt-5">
                <input type="file"
                       id={controllId}
                       accept="image/*"
                       capture="camera"
                       onChange={event => this.onChangeFile(event)}
                />
                <label className="btn"
                       htmlFor={controllId}
                       title="Capturar Documento">
                  <i className="fa fa-camera mr-0"/>
                </label>

                <MsgEnProceso isEnProceso={this.state.isEnProceso}/>

                <div className="imgFiles">
                  {listaImg}
                </div>

              </div>


            </div>
          </Fragment>)
      ;


      listaImg = sol.listaImg
          .map((item, index) => {

            const cssImg = "imgBrowserDoc " + (item.isRotacion > 0 ? 'imgr' + item.isRotacion.toString() : '')

            return (
                <div className="wrapperImgDoc" key={index}>

                  <h4 className="tituloSolBrowser">
                    Foto {index + 1} / {numFotos}
                  </h4>

                  <div className="wrapperImg">
                    <img
                        src={item.url}
                        alt="Documento"
                        className={cssImg}
                    />
                  </div>

                </div>
            );

          })
      ;

    } else {

      elemVerSol = (
          <div className="text-center">
            <button
                className="btn-sm btn-secondary"
                onClick={event => this.onClicVerSol()}
            >
              <i className="fas fa-download mrBTN"/>
              Ver Imágenes
            </button>

          </div>
      );
    }


    const numImg = sol.listaImg.length;
    const labImg = <span className="label label-success float-right">{numImg} <i className="fas fa-camera"/></span>


    return (
        <div className={cssSol}>
          {tagUpdated}
          <h4 className="tituloCliente">
            <span className="mrBTN">{index})</span>
            {nombreCliente}
            {labImg}
          </h4>

          <h5 className="tituloTipo">
            {tipo}
            <span className="fRight">
              <i className="fas fa-calendar mrBTN"/>{fregDMY}
              <i className="fas fa-clock mrBTN mlBTNXL"/>{fregHora}
            </span>
          </h5>

          <h4 className={classEstatus}>
            {estatus}
          </h4>

          <ul className="listaComSol">
            {listaItemCom}
          </ul>

          {elemVerSol}

          {elemUpLoad}
          {listaImg}

        </div>
    );
  }

  async onChangeFile(event) {

    if (event.target.files.length === 0) {
      this.setState({
        isEnProceso: false
      });
      return;
    }

    let file = event.target.files[0];

    const {sol} = this.props;
    let {idDocTipoSelected} = this.state;

    if (!idDocTipoSelected) {
      idDocTipoSelected = sol.listaDocTipo[0]._id;
    }

    const fileName = file.name;

    this.setState({
      isEnProceso: true
    });

    //1.-solicitar el link para subir la imagen ********* */
    const ext = "jpg";
    let respuesta = await DataService.getUploadS3Url(sol._id, idDocTipoSelected, ext);

    if (!respuesta.success) {
      LibToast.alert(respuesta.msg);
      return;
    }


    const {urlS3, key} = respuesta.data;


    //2.-subir directamente a S3 la iamgen ************** */
    const respuestaS3 = await DataReqJson.requestPUTFile(urlS3, file);


    if (respuestaS3.toString() !== "") {
      LibToast.alert('Error de  S3 al subir imagen :' + respuestaS3);
      return;
    }

    /*3.- solicitar el url signed de la imagen ********* */

    const respuestaUrl = await DataService.getS3Url(key);

    if (!respuestaUrl.success) {
      LibToast.alert('Error de S3 al solicitar :' + respuestaUrl);
      return;
    }

    const imgNew = {
      idDocTipo: idDocTipoSelected,
      url: respuestaUrl.data.url,
      file
    };

    sol.listaImg.push(imgNew);
    sol.idEstatus = 'espera';

    this.props.onUpdateSol(sol);

    this.setState(
        {isEnProceso: false}
    );

    LibToast.success("Nueva Imagen Agregada");

    const ope = await DataService.finalizarSolicitud(sol);

    //Ponerlo de nueva en espera
    LibMsgWSService.cambioEstatus(sol, 'espera');


  }
}

export default SolItem;
