import React, {Component} from 'react';
import SolItem from "./SolItem";
import {DebounceInput} from "react-debounce-input";
import './SolicitudIndexPage.css';

class SolicitudIndexPage extends Component {

  constructor(props, context) {
    super(props, context);

    this.state = {
      valorFiltro: ''
    };
  }

  onFiltroChange = valorFiltro => {
    this.setState({ valorFiltro });
  };



  render() {

    const {listaSol} = this.props;

    // const listaItem=
    const valorFiltro=this.state.valorFiltro.trim().toLowerCase();

    let  listaFiltrada= listaSol;
    if(valorFiltro!==''){

      const listaPropEnFiltro = ['nombreCliente','estatus'];

      const fnFiltro = (texto, item) => {
        let isConincidencia = false;
        listaPropEnFiltro.forEach(p => {
          if (item[p].toLowerCase().includes(texto)) {
            isConincidencia = true;
          }
        });
        return isConincidencia;
      };


      listaFiltrada = listaSol.filter(item=>{

        return fnFiltro(valorFiltro, item);

      });
    }

    const listaItems = listaFiltrada.map((item,index) => {
      return (
          <SolItem
              sol={item}
              index={index}
              key={item._id}
              onUpdateSol={this.props.onUpdateSol}
          />
      );
    });


    return (
        <div>

          <div className="wrapperFiltro" title="Filtrar por estatus y nombre del cliente">
            <i className="fa fa-filter"/>
            <DebounceInput
                minLength={2}
                value={this.state.valorFiltro}
                debounceTimeout={500}
                className="txtFiltro"
                placeholder="Filtro"
                onChange={event => this.onFiltroChange(event.target.value)}
            />

            <span className="btn btn-sm btn-secondary"
              onClick={event=>this.onFiltroChange('')}
            >
              <i className="fas fa-undo"/>
            </span>
          </div>

          {listaItems}
        </div>
    );
  }
}

export default SolicitudIndexPage;
