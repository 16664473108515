import React, { Component } from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import ObserverTopBarEvent from './ObserverTopBarEvent';
import AuthService from '../../services/authService/AuthService';
import UrlPages from '../../pages/UrlPages';
import { Link } from 'react-router-dom';

class TopBar extends Component {
  constructor(props, context) {
    super(props, context);

    let nick = '';

    if (AuthService.getIsAuthenticated()) {
      nick = AuthService.getUser().nick;
    }

    this.state = {
      nick
    };

    ObserverTopBarEvent.registrarFnOnNickchange(this.onChangeNick);
  }



  onChangeNick = nick => {
    this.setState({
      nick
    });
  };

  onLogOut = () => {
    this.setState({
      isAutenticado: false
    });

    AuthService.setLogOut();
    window.location = UrlPages.LOGIN;
  };

  render() {
    const nombreUsuario = this.state.nick;

    const listaLinks = [
      { label: 'Crear Solicitud', url: UrlPages.SOLICITUD_CREAR },
      { label: 'Solicitudes Enviadas', url: UrlPages.SOLICITUD_INDEX },
      { label: 'Mensajes Recibidos', url: UrlPages.MSG_INDEX },
      { label: 'Editar Perfil ' + nombreUsuario, url: UrlPages.VER_PERFIL },
      { label: 'div' }
    ];

    const listaNav = listaLinks.map((item, index) => {
      const label = item.label;

      const key = 'mnu' + index.toString();

      const classNameLink =
        'nav-link' + (item.url === this.props.currentUrlRel ? ' active ' : '');
      if (label === 'div') {
        return <hr className="divisornav" key={key} />;
      } else {
        return (
          <Link className={classNameLink} to={item.url} key={key}>
            {item.label}
          </Link>
        );
      }
    });

    return (
      <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
        <Navbar.Brand href="#home">Beryhuert</Navbar.Brand>
        <Nav className="color-white">{this.props.titulo}</Nav>
        <Nav className="mr-auto color-white ml-5">{this.props.children}</Nav>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav activeKey={this.props.currentUrlRel}>
            {listaNav}
            <span
              className="nav-link cursor-pointer"
              onClick={event => {
                this.onLogOut();
              }}
            >
              Salir
            </span>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

export default TopBar;
