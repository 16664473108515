import React, {Component, Fragment} from 'react';
import TopBar from '../../components/topbar/TopBar';
import UrlPages from '../UrlPages';
import AuthService from '../../services/authService/AuthService';
import AppContext from "../../app/AppContext";
import SolicitudIndexPage from "./SolicitudIndexPage";
import HOCProtectedPage from "../HOCProtectedPage";

class SolicitudIndexPageHOC extends Component {


  render() {
    //verificar la authenticacion
    if (!AuthService.getIsAuthenticated()) {
      this.props.history.push('/');
      return null;
    }

    return (
        <HOCProtectedPage>
          <AppContext.Consumer>
            {context => (
                <Fragment>
                  <TopBar
                      titulo="Solicitudes"
                      usuario={context.usuario}
                      currentUrlRel={UrlPages.SOLICITUD_INDEX}
                  />
                  <SolicitudIndexPage
                      listaSol={ context.listaSol}
                      onUpdateSol={ context.onUpdateSol}
                  />
                </Fragment>
            )
            }
          </AppContext.Consumer>
        </HOCProtectedPage>
    );
  }
}

export default SolicitudIndexPageHOC;
