import React, { Component } from 'react';
import TopBar from '../../components/topbar/TopBar';
import UrlPages from '../UrlPages';
import AuthService from '../../services/authService/AuthService';

class PerfilPage extends Component {
  render() {
    //verificar la authenticacion
    if (!AuthService.getIsAuthenticated()) {
      this.props.history.push('/');
      return null;
    }

    return (
      <div>
        <TopBar
          titulo="Configurar Cuenta"
          currentUrlRel={UrlPages.VER_PERFIL}
        ></TopBar>
        Perfil de la  cuenta
      </div>
    );
  }
}

export default PerfilPage;
