import LibTexto from "../../lib/LibTexto";

class Singleton {

  KEY_LOCALSTORAGE_TOKEN = 'vend_solmc_token';
  KEY_LOCALSTORAGE_TOKEN_TSVALID = 'vend_solmc_token_tsvalid';
  KEY_LOCALSTORAGE_PUBLICDATA = 'vend_solmc_public_data';
  KEY_LOCALSTORAGE_AREASELECTED = 'vend_solmc_area';

  constructor() {
    this.public_data = null;
    this.token = null;
    this.tsValid = 0;
    this.idArea = 'NA';
    this.area = 'NA';

    if (
        window.localStorage.getItem(this.KEY_LOCALSTORAGE_TOKEN) &&
        window.localStorage.getItem(this.KEY_LOCALSTORAGE_PUBLICDATA) &&
        window.localStorage.getItem(this.KEY_LOCALSTORAGE_TOKEN_TSVALID)
    ) {
      this.token = window.localStorage.getItem(this.KEY_LOCALSTORAGE_TOKEN);

      let texto = window.localStorage.getItem(this.KEY_LOCALSTORAGE_PUBLICDATA);
      this.public_data = JSON.parse(texto);


      this.tsValid = parseInt(
          window.localStorage.getItem(this.KEY_LOCALSTORAGE_TOKEN_TSVALID)
      );
      const tsNow = Date.now();
      if (this.tsValid < tsNow) {
        this.setLogOut();
      }


      const jsonAreaSelected = (window.localStorage.getItem(this.KEY_LOCALSTORAGE_AREASELECTED))

      let areaSelected = {idArea: 'NA', area: ''};
      if (jsonAreaSelected) {
        areaSelected = JSON.parse(jsonAreaSelected);
      }

      this.idArea = areaSelected.idArea;
      this.area = areaSelected.area;

    }
  }

  registrarLogin(public_data, token, isSaveLocalStorage = false) {
    if (!token || !public_data) {
      throw new Error('No se puede hacer session sin datos correctos');
    }

    this.token = token;

    public_data.idSession = LibTexto.getRandomString();

    this.public_data = public_data;


    if (isSaveLocalStorage && token && public_data) {

      window.localStorage.setItem(this.KEY_LOCALSTORAGE_TOKEN, token);

      const json = JSON.stringify(public_data);
      window.localStorage.setItem(this.KEY_LOCALSTORAGE_PUBLICDATA, json);

      const ts = Date.now();
      this.tsValid = ts + public_data.lapso;
      window.localStorage.setItem(
          this.KEY_LOCALSTORAGE_TOKEN_TSVALID,
          this.tsValid
      );

      const listaAreas = public_data.listaAreasVendedor || [];

      if(listaAreas.length===0){
        listaAreas.push({idArea: 'NA', area: 'NA'});
      }

      this.setAreaSelected(listaAreas[0]);

    }
  }

  setAreaSelected(areaSelected) {
    window.localStorage.setItem(
        this.KEY_LOCALSTORAGE_AREASELECTED,
        JSON.stringify(areaSelected)
    );

    this.idArea = areaSelected.idArea;
    this.area = areaSelected.area;


  }


  getIsAuthenticated() {

    if (this.token === null) {
      return false;
    }
    const tsCurrent = Date.now();

    //validar el tiempo de vida de la sesion
    if (this.tsValid < tsCurrent) {
      return false;
    }

    return true;
  }

  getUser() {
    return this.public_data;
  }

  getIsAdmin() {
    return this.public_data.isAdmin === true;
  }

  getIsVendedor() {
    return this.public_data.isVendedor === true;
  }

  getToken() {
    return this.token;
  }

  getArea() {
    return this.area;
  }

  getIdArea() {
    return this.idArea;
  }

  getListaAreasVendedor() {
    return this.public_data.listaAreasVendedor;
  }


  setLogOut() {
    this.token = null;
    this.public_data = null;
    this.tsValid = 0;

    window.localStorage.removeItem(this.KEY_LOCALSTORAGE_TOKEN);
    window.localStorage.removeItem(this.KEY_LOCALSTORAGE_TOKEN_TSVALID);
    window.localStorage.removeItem(this.KEY_LOCALSTORAGE_PUBLICDATA);

  }

  updateData(dataUpdate) {
    //lista campos permitidos
    const listaCamposAllow = ['email', 'nombre', 'nick'];

    listaCamposAllow.forEach(c => {
      if (dataUpdate[c] !== undefined) {
        this.public_data[c] = dataUpdate[c];
      }
    });
  }
}

const AuthService = new Singleton();


export default AuthService;
