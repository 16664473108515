import configApi from './configApi';
import ApiReqJson from './ApiReqJson';

const DataApi = {
  Login: {
    ingresar: async (email, password) => {
      const url = configApi.urlBase + `/login`;
      return await ApiReqJson.requestPOST(url, {email, password});
    },
    requestRecPass: async email => {
      const url = configApi.urlBase + `/recpass`;
      const ope = 'request';
      return await ApiReqJson.requestPOST(url, {email, ope});
    },
    executeRecPass: async (email, token, newPassword) => {
      const url = configApi.urlBase + `/recpass`;
      const ope = 'execute';
      return await ApiReqJson.requestPOST(url, {
        email,
        ope,
        token,
        newPassword
      });
    }
  },

  dataAuxiliar: async () => {
    const url = configApi.urlBase + `/vendedor/data_auxiliar`;
    return await ApiReqJson.requestGET(url);
  },
  registrarNewSolicitud: async (nombre, idSolTipo, solTipo, area) => {

    const dataObject = {
      nombre,
      idSolTipo,
      solTipo,
      area
    };

    const url = configApi.urlBase + `/vendedor/reg_new_sol`;

    return await ApiReqJson.requestPOST(url, dataObject);
  },
  getUploadS3Url: async (idSol, idTipoDoc, fileExt) => {
    const url = configApi.urlBase + `/vendedor/get_uploads3_url?idSol=${idSol}&idTipoDoc=${idTipoDoc}&fileExt=${fileExt}`;
    return await ApiReqJson.requestGET(url);
  },
  getS3Url: async (key) => {
    const url = configApi.urlBase + `/vendedor/get_s3_url?key=${key}`;
    return await ApiReqJson.requestGET(url);
  },

  finalizarSolicitud: async (sol) => {
    const dataObject = {
      idSol: sol._id
    };

    const url = configApi.urlBase + `/vendedor/finalizar_sol`;
    return await ApiReqJson.requestPOST(url, dataObject);
  },

  solicidudesPendientes: async () => {
    const url = configApi.urlBase + `/mc/sol_pendientes`;
    return await ApiReqJson.requestGET(url);
  },

  getSol: async id => {
    const url = configApi.urlBase + `/vendedor/sol?id=${id}`;
    return await ApiReqJson.requestGET(url);
  },
  /* ******************************************************************* */

  deleteImgFile: async (year, mes, idSolicitud, idImg) => {
    /*TODO*/
    const url = configApi.urlBase + `/vendedor/img_sol_delete`;
    return await ApiReqJson.requestPOST(url);
  },

  GetImgFile: async (year, mes, idSolicitud, idImg) => {
    /*TODO*/
    const url = configApi.urlBase + `/vendedor/img_sol_get`;
    return await ApiReqJson.requestGET(url);
  }

};

export default DataApi;
