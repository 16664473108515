import React from 'react';

import {BrowserRouter as Router, Redirect, Route, Switch} from 'react-router-dom';
import ObserverWindowH from '../lib/ObserverWindowH';

import AuthService from '../services/authService/AuthService';
import UrlPages from '../pages/UrlPages';

import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import LoginPage from '../pages/login/LoginPage';
import SolicitudCrearPageHOC from '../pages/solicitudCrear/SolicitudCrearPageHOC';

import MsgIndexPageHOC from '../pages/mensajeIndex/MsgIndexPageHOC';
import PerfilPage from '../pages/perfil/PerfilPage';
import DataService from "../services/dataService/DataService";
import LibToast from "../lib/LibToast";
import LogoutPage from "../pages/login/LogoutPage";
import ContextProvider from "./ContextProvider";
import SolicitudIndexPageHOC from "../pages/solicitudIndex/SolicitudIndexPageHOC";



class App extends React.Component {
  constructor(props) {
    super(props);
    const isAutenticado = AuthService.getIsAuthenticated();

    this.state = {
      isAutenticado
    };
  }

  async componentDidMount() {

    window.addEventListener('resize', () => {
      const h = window.innerHeight;
      ObserverWindowH.onWindowsRezise.raise(h);
    });


    if (AuthService.getIsAuthenticated()) {
      const respuesta = await DataService.dataAuxiliar();
      if (!respuesta.success) {
        LibToast.alert(respuesta.msg);
        AuthService.setLogOut();
        window.location = UrlPages.LOGIN;
      }

    }

  }

  onLogIn = () => {
    window.location = UrlPages.SOLICITUD_CREAR;
  };

  render() {

    return (
        <ContextProvider>
          <div id="App">
            <Router>
              <Switch>
                {/* ---------------------------------- */}
                <Route
                    path={UrlPages.LOGIN}
                    exact
                    component={() => <LoginPage onLogIn={() => this.onLogIn()}/>}
                />

                <Route
                    path={UrlPages.LOGOUT}
                    exact
                    component={() => <LogoutPage/>}
                />
                {/* ---------------------------------- */}

                <Route
                    path={UrlPages.SOLICITUD_CREAR}
                    exact={true}
                    component={() => <SolicitudCrearPageHOC listaTipoSolicitud={this.state.listaTipoSolicitud}
                    />
                    }
                />

                <Route
                    path={UrlPages.SOLICITUD_INDEX}
                    exact
                    component={SolicitudIndexPageHOC}
                />

                <Route path={UrlPages.MSG_INDEX} exact component={MsgIndexPageHOC}/>

                <Route path={UrlPages.VER_PERFIL} exact component={PerfilPage}/>

                <Redirect to="/"/>
                {/* ---------------------------------- */}
              </Switch>
            </Router>

            <ToastContainer/>
          </div>

        </ContextProvider>
    );
  }
}

export default App;
