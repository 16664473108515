import React, { Component } from 'react';
import LibToast from '../../lib/LibToast';
import LibValidacion from '../../lib/LibValidacion';

class FormRecuperar extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      email: props.email,
      password: '',
      isEnProceso: props.isEnProceso
    };
  }

  onTxtChange = (campo, valor) => {
    let dic = {};
    dic[campo] = valor;
    this.setState(dic);

    if (campo === 'email') {
      this.props.onUpdateEmail(valor);
    }
  };

  onSubmit = async event => {
    event.preventDefault();

    const email = this.state.email.trim();

    let isValid = true;

    if (email === '') {
      LibToast.alert('Falta el Email');
      isValid = false;
    } else {
      //validar email
      isValid = LibValidacion.isEmailValid(email);
    }

    if (!isValid) {
      return;
    }

    await this.props.onSubmitForm(email);
  };

  render() {
    const iconLoading = this.props.isEnProceso && (
      <div className="center-align">
        <i className="fa fa-cog fa-spin " />
        <span className="pl-1"> En Proceso</span>
      </div>
    );

    return (
      <form onSubmit={this.onSubmit} className="login-form">

        <div className="form-group">
          <label htmlFor="emailrec">
            <i className="fas fa-envelope mr-1" />
            Email address
          </label>
          <input
              id="emailrec"
              value={this.state.email}
              className="form-control"
              placeholder="Email"
              onChange={event => this.onTxtChange('email', event.target.value)}
              type="email"
          />
        </div>


        <div className="text-center">
          <button className="btn btn-primary w-50">
            Solicitar Recuperación
          </button>
        </div>

        {iconLoading}

        <p className="mt-5 pt-4">
              <span
                  className="linkRecordar"
                  onClick={event => this.props.onToogleForm()}
              >
                <i className="fas fa-arrow-circle-left mr-1" />
                Ingresar con Password
              </span>
        </p>

      </form>
    );
  }
}

export default FormRecuperar;
