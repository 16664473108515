import React, {Component, Fragment} from 'react';
import TopBar from '../../components/topbar/TopBar';
import UrlPages from '../UrlPages';
import AuthService from '../../services/authService/AuthService';
import HOCProtectedPage from "../HOCProtectedPage";
import AppContext from "../../app/AppContext";
import SolicitudIndexPage from "../solicitudIndex/SolicitudIndexPage";
import MsgIndexPage from "./MsgIndexPage";

class MsgIndexPageHOC extends Component {
  render() {

    if (!AuthService.getIsAuthenticated()) {
      this.props.history.push('/');
      return null;
    }

    return (
        <HOCProtectedPage>
          <AppContext.Consumer>
            {context => (
                <Fragment>
                  <TopBar
                      titulo="Mensajes"
                      usuario={context.usuario}
                      currentUrlRel={UrlPages.MSG_INDEX}
                  />
                  <MsgIndexPage
                      listaMsg={ context.listaMsg}
                  />
                </Fragment>
            )
            }
          </AppContext.Consumer>
        </HOCProtectedPage>
    );
  }
}

export default MsgIndexPageHOC;
