import AuthService from '../services/authService/AuthService';
import UrlPages from './UrlPages';

import React from 'react';

class HOCProtectedPage extends React.Component {
  render() {
    if (!AuthService.getIsAuthenticated()) {
      window.location = UrlPages.LOGIN;
      console.log('no autenticated');
      return null;
    }

    return this.props.children;
  }
}

export default HOCProtectedPage;
