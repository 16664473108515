import React, {Component, Fragment} from 'react';
import MsgItem from "./MsgItem";




function MsgIndexPage({listaMsg}){


  if(listaMsg.length===0){
    return <p className="text-center pt-4">Sin Mensajes</p>;
  }

  const listaItems = listaMsg.map((item,index) => {


    return (
        <MsgItem
            msg={item}
            index={index}
            key={index}
        />
    );
  });


  return (
      <div>
        {listaItems}
      </div>
  );

}

export default MsgIndexPage;
