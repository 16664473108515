const ProCalculoAvance = (listaImg, listaDocTipo) => {

  const listaIDTipoRequerido = listaDocTipo
      .filter(item => {
        return !item.isNoRequerido
      })
      .map(item => {
        return item._id
      })
  ;


  if (listaIDTipoRequerido.length === 0) {
    return 100;
  }

  //asociar los tipodeDcoPresente que tenemos

  let numTiposConImg = 0;

  listaIDTipoRequerido.forEach(idDocTipo => {

    const listaImgTipo = listaImg.filter(item => {
      return item.idDocTipo === idDocTipo;
    });

    if (listaImgTipo.length > 0) {
      numTiposConImg++;
    }

  });


  let avance = 100 * numTiposConImg / listaIDTipoRequerido.length;
  avance = parseInt(avance);

  //buscar
  return avance;
};


export default ProCalculoAvance;
