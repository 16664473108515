import ConfigApp from '../dataService/dataApi/configApi';
import AuthService from '../authService/AuthService';

const isDebug = true;


const getData = (sol, tipo) => {

  const idSession = AuthService.getUser().idSession;
  const idArea = AuthService.getIdArea();
  const area = AuthService.getArea();
  const usuario = AuthService.getUser().nick;

  return {
    origen: 'v',
    usuario,
    idSession,
    tipo,
    idSol:sol._id,
    idUsuario:sol.idUsuario,

    solEstatus:sol.estatus,
    nickVendedor:sol.nick,
    nombreCliente:sol.nombreCliente,
    idArea: sol.idArea,
    area: sol.area
  };
};


const socket = window.io.connect(ConfigApp.urlWS, {forceNew: true});

//{tipo:'delete, run(idSol)}
const listaCallback = [];

socket.on('m', function (data) {

  if (isDebug) {
    console.log('m', data);
  }

  if(!AuthService.getIsAuthenticated()){
    return;
  }

  const nick=AuthService.getUser().nick;
  //si es test o de otro vendedor no nos interesa
  if(data.isTest || data.origen==="v" || data.nick !== nick ){
    return;
  }

  const idSession = AuthService.getUser().idSession;

  if ( data.idSession === idSession) {
    //No procesamois los mensajes de la misma session
    if (isDebug) {
      console.log('salir - mensaje del mismo usuario');
    }
    return;
  }


  listaCallback.forEach(async ocb => {
    if (ocb.tipo === data.tipo) {
      await ocb.run(data);
    }
  });

});


const LibMsgWSService = {
  addCBEvent: (tipo, callback) => {
    listaCallback.push({tipo, run: callback});
  },

  cambioEstatus: (sol, idEstatus) => {
    const data = getData(sol, 'sol_cambioEstatus');

    data.idEstatus = idEstatus;

    socket.emit('m', data);
  }
};

export default LibMsgWSService;
