import React, {Component} from 'react';
import AppContext from './AppContext';
import AuthService from '../services/authService/AuthService';
import DataService from '../services/dataService/DataService';
import LibMsgWSService from '../services/msgWSService/LibMsgWSService';

import LibToast from "../lib/LibToast";

class ContextProvider extends Component {

  constructor(props, context) {

    super(props, context);

    this.requestDataAux.bind(this);


    const onComenzarSolicitud = async (nombre) => {
      const idSolTipo = this.state.solTipo._id;
      const solTipo = this.state.solTipo.tipo;

      //Crear la solicitud
      const nombreArea = AuthService.getArea();
      const respuesta = await DataService.registrarNewSolicitud(nombre, idSolTipo, solTipo, nombreArea);

      if (!respuesta.success) {
        LibToast.alert(respuesta.msg);
        return;
      }

      const sol = respuesta.data;

      sol.listaImg = [];

      this.setState({
        sol
      });
    };


    const onCancelarSolicitud = async () => {
      this.setState({
        sol: null,
      })
    };


    const onSetSolTipo = (solTipoSelected) => {
      this.setState({
        solTipo: solTipoSelected
      });
    };


    const onAddSolFinalizada = (sol) => {

      if (!sol._id) {
        const listaSol = [sol, ...this.state.listaSol];
        this.setState({listaSol});
      }

      //Notificar de la nueva solicitud
      LibMsgWSService.cambioEstatus(sol, 'espera');

    };

    const onUpdateSol = (sol) => {

      const listaSol = this.state.listaSol
          .map(item => {

            if (item._id !== sol._id) {
              return item;
            }

            Object.keys(sol).forEach(p => {
              item[p] = sol[p];
            });

            return item;

          })
      ;

      this.setState({listaSol});


    };

    onComenzarSolicitud.bind(this);
    onCancelarSolicitud.bind(this);
    onSetSolTipo.bind(this);
    onAddSolFinalizada.bind(this);
    onUpdateSol.bind(this);

    this.state = {
      isLoadedDataAux: false,
      listaSolTipo: [],
      listaSolEstatus: [],
      listaSol: [],
      listaMsg: [],
      sol: null,
      solTipo: null,
      usuario: AuthService.public_data,
      onComenzarSolicitud,
      onCancelarSolicitud,
      onSetSolTipo,
      onAddSolFinalizada,
      onUpdateSol
    };


    //asociar los eventos ******************************************
    LibMsgWSService.addCBEvent('delete', (data) => {
      data.isNew = true;
      const listaMsg = [data, ...this.state.listaMsg];
      this.setState({listaMsg});

      //quital la solicitud
      const listaSol = this.state.listaSol
          .filter(item => {
                return item._id !== data.idSol
              }
          );

      this.setState({listaSol});
    });

    LibMsgWSService.addCBEvent('sol_cambioEstatus', (data) => {
      data.isNew = true;
      const listaMsg = [data, ...this.state.listaMsg];
      this.setState({listaMsg});

      /*Realizar el cambio de estatus de la solicitud */
      const solSelected = this.state.listaSol
          .find(item => {
                return (item._id === data.idSol);
              }
          )
      ;

      const listaOtrasSol = this.state.listaSol
          .filter(item => {
                return (item._id !== data.idSol);
              }
          )
      ;

      let listaSol = [];

      if (solSelected) {
        solSelected.idEstatus = data.idEstatus;
        solSelected.isUpdated =true;
        listaSol = [solSelected, ...listaOtrasSol];
      } else {
        listaSol = listaOtrasSol;
      }



      this.setState({listaSol});

    });

  }

  componentDidMount() {
    if (AuthService.getIsAuthenticated()) {
      this.requestDataAux();
    }
  }


  requestDataAux = async () => {

    if (AuthService.getIsAuthenticated()) {

      const respuesta = await DataService.dataAuxiliar();

      if (!respuesta.success) {
        return false;
      }

      const dataAux = {
        listaSolTipo: respuesta.data.listaSolTipo,
        listaSolEstatus: respuesta.data.listaSolEstatus,
        listaMsg: respuesta.data.listaMsg,
        listaSol: respuesta.data.listaSol,
        solTipo: respuesta.data.listaSolTipo[0],
        isLoadedDataAux: true
      };

      this.setState(dataAux);

    }
  };

  render() {
    const dataContext = {};
    Object.keys(this.state).forEach(k => {
      dataContext[k] = this.state[k];
    });

    dataContext.setData = this.setData;
    dataContext.requestDataAux = this.requestDataAux;

    dataContext.onCrudSol = {
      updateItem: (newSol) => {

      }
    };

    return (
        <AppContext.Provider value={dataContext}>
          {this.props.children}
        </AppContext.Provider>
    );
  }
}

export default ContextProvider;
